import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App lk1">
      <header className="App-header">
        <div className="logo-block">
          <img src={logo} className="logo-p" alt="logo" />
          <a href="/" className='logo-t'>smilescorp::</a>
        </div>
      </header>
    </div>
  );
}

export default App;
